.testimonials {
    background-image: url(brush.jpg);
    background-color: black;
    width: 100%;
    height: auto;
    text-align: center;
    padding-bottom: 1px;
    width: 100%;
  }
  
  .testimonials h2 {
    padding: 2rem 0;
  }
  
  .testimonials-content {
    text-align: center;
    max-width: 53.125rem;
    margin: 0 auto 2rem auto;
  }
  
  .myCarousel {
    color: var(--primary-white);
    background: transparent;
    margin-top: -6%;
    width: 54%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 6%;
    padding-right: 6%;
    padding-bottom: 8%;
    height: 17.875rem;
  }
  
  .carousel .slide img {
    width: 10rem !important;
  }
  
  .myCarousel h3 {
    color: var(--primary-cyan);
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 1rem;
  }
  
  .myCarousel p {
    color: var(--primary-grey);
    padding-top: 1rem;
  }
  
  .carousel .slide {
    background-color: transparent !important;
    height: 100% !important;
    padding-top: 2rem;
  }

  .test-image {
    border-radius: 50%;
  }