 /*------ Experience -------*/

 .experience {
  background-color: black;
  padding-top: 10px;
 }
.experience-wrapper {
    position: relative;
    padding: 3.125rem 0;
    overflow: hidden;
    font-family: 'Roboto Condensed';
  }
  
  .experience h2 {
    text-transform: uppercase;
    color: var(--primary-cyan);
    font-family: 'Roboto Condensed';
    padding-top: 10px;
    
  }
  
  .experience-wrapper::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    margin-left: -0.0625rem;
    width: 0.125rem;
    height: 100%;
    background: var(--primary-dark-grey);
    z-index: 1;
    
    
  }
  
  .timeline-block {
    width: calc(50% + 0.5rem);
    display: flex;
    justify-content: space-between;
    clear: both;
    
  }
  
  .timeline-block-right {
    float: right;
    
  }
  
  .timeline-block-left {
    float: left;
    direction: rtl;
  }
  
  .timeline-content {
    width: 95%;
    padding: 0 0.9375rem;
    color: white;
    direction: ltr;
    
  }
  
  .marker {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 0.3125rem solid var(--primary-cyan);
    background: var(--primary-cyan);
    margin-top: 0.625rem;
    z-index: 1;
  }
  
  .timeline-content h3 {
    margin-top: 0.3125rem;
    color: var(--primary-cyan);
    font-weight: 900;
  }
  
  @media (max-width: 48rem) {
    .experience {
      padding: 0.7rem;
      
    }
  
    .container {
      padding-left: 0 !important;
      
    }
  
    .container::before {
      left: 0.5rem;
      margin-bottom: 1.875rem;
    }
  
    .timeline-block-right {
      float: none;
      width: 100%;
    }
  
    .timeline-block-left {
      float: none;
      width: 100%;
      direction: ltr;
    }
  }