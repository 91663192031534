.services {
    text-align: center;
    padding-bottom: 2rem;
    background-color: black;
    font-family: 'Roboto Condensed';
  }
  
  .services h2 {
    color: var(--primary-cyan);
    text-transform: uppercase;
    
  }
  
  .circle {
    position: relative;
    margin: 0.375rem auto;
    background: var(--primary-white);
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
  }
  
  .icons {
    font-size: 1.4rem;
    color: var(--primary-cyan);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .box {
    height: 16rem;
    border-bottom: 0.3125rem var(--primary-cyan);
    border-radius: 10px;
    padding: 0.3rem;
    margin-bottom: 1.875rem;
    background-color: var(--primary-cyan);
    opacity: 80%;
  }
  
  .box:hover {
    background-color: var(--primary-cyan);
    cursor: pointer;
    opacity: 100%;
  }
  
  .box:hover .circle {
    background-color: var(--primary-dark-grey);
  }
  
  .box:hover .icons {
    color: var(--primary-cyan);
  }
  
  .box:hover p {
    color: var(--primary-dark-grey);
  }

  .box:hover h3 {
    color: var(--primary-dark-grey);
  }
  .services h3 {
    color: var(--primary-white);
  }

  .services p {
    color: var(--primary-white);

  }