.icon1 {
    max-width: 160px;
}
.icon2 {
    max-width: 80px;
}
.icon3 {
    max-width: 150px;
}


.socials-wrapper {
    padding-top: 20px;
    text-align: center;
  }
  .socials {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-around;
    border: 2px solid black;
  }
  #socials {
    background-color: black;
  }
