/*********************** NAVBAR **************************/

.logo {
  width: 90px;
}

nav {
  z-index: 2;
}

.navbar {
  background-color: black !important;
}


/*------ MENU LINKS -------*/
nav a.nav-link {
  font-size: 15px;
  letter-spacing: 2px;
  cursor: pointer;
  font-family: 'Roboto Condensed';
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav li a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-darkgrey);
}

.navbar-nav li a.active,
.navbar-nav li a.active:focus {
  border-top: 0.1875rem solid var(--primary-cyan);
}

.navbar-nav li a:hover {
  border-top: 0.1875rem solid var(--primary-cyan);
}

@media (max-width: 768px) {
  .logo {
    width: 8rem;
  }
}
