@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

body, h1, h2, h3, h4, p {
  font-family: 'Roboto Condensed';
}

h2 {
  color: var(--primary-cyan);
  text-transform: uppercase;
}

#github-corner {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
  }

:root {
  --primary-white: #fff;
  --primary-cyan: #01EFD1;
  --primary-dark-grey: #28282B;

}
