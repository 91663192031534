/*********************** HEADER **************************/

.header-wrapper {
    background: url(header_background.jpg) no-repeat center center/cover;
    height: 100vh;
    font-family: 'Roboto Condensed';
  }
  
  .main-info {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Roboto Condensed';
    color: var(--primary-cyan);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    /* z-index: 1; */
  }
  .welcome {
    font-size:75px;
  }
  
  .typed-text {
    font-size: 3rem;
    color: var(--primary-white);
  }
  
  /*------- BUTTON -----*/
  .btn-main-offer {
    border: 1px solid var(--primary-cyan);
    text-transform: uppercase;
    border-radius: 10px;
    padding: 0.625rem;
    color: var(--primary-white);
    margin-top: 2rem;
  }
  
  .btn-main-offer:hover {
    text-decoration: none;
    color: var(--primary-white) !important;
    background-color: var(--primary-cyan);
    transition: 0.2s ease-in-out;
  }
  
  @media (max-width: 768px) {
    #tsparticles {
      display: none !important;
    }
  
    .main-info h1 {
      font-size: 1.7rem;
    }
  
    .typed-text {
      font-size: 1.3rem;
    }
  }
  